.App{
  width:80%;
  margin:auto;
}

nav{
  position: fixed;
  font-family: "Sk";
  top:0;
  width:100%;
  background-color: #ffffff;
  z-index: 20 ;
  /* box-shadow: 0px 4px 8px rgba(162, 182, 185, 0.1); */
}

/* .midbg{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color: rgba(0,0,0,0);
  z-index: 7;
}

.singleImage:hover + .midbg{
  background-color: rgba(0,0,0,0.5);
  transition: 0.2s;
} */

.logo{
  font-size: 20px;
}

.navinner{
  display: flex;
  justify-content: space-between;
  width:80%;
  
}

.navinner a{
  color:rgb(0, 0, 0);
}

.top-right{
  padding:16px 16px;

}

.container{
  padding-top:48px;
}

.grid{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.padding{
  width:25%;
}

.singleImage:hover{
  transform: scale(1.2);
  z-index: 10;
  transition: 0.2s ease-in-out;
  border-radius: 16px;
  
}

img{
  width:98%;
  padding:2%;
  object-fit: cover;
  height:98%;
  transition: 0.2s ease-in-out;
  border-radius: 16px;
}

img:hover{
  border-radius: 0px;
  transition: 0.2s ease-in-out;
  cursor: pointer;

}

.title{
  padding-top:64px;
  font-size: 64px;
}

.tagline{
  font-size: 20px;
  color:#c0c0c0;
  font-weight: bold;
  font-family: 'Sk';
}

.cta{
  border-radius: 48px;
  position: fixed;
  bottom: 48px;
  right:48px;
}
.cta a{
  color:rgb(0, 0, 0);
  font-size: 24px;
}

.end{
  padding:48px 0px;
  text-align: center;
  color:#c0c0c0;
  font-weight: bold;
  font-family: 'Sk';
}

@media(max-width:768px){
  img{
    width:98%;
    padding:2%;
    object-fit: cover;
    height:98%;
    transition: 0.2s ease-in-out;
    border-radius: 16px;
  }
  .padding{
    width:33.333%;
  }
  img{
    width:96%;
    padding:4%;
    object-fit: cover;
    height:98%;
    transition: 0.2s ease-in-out;
    border-radius: 16px;
  }
}

@media(max-width:455px){
  .App{
    width:96%;
    margin:auto;
  }
  .title{
    font-size: 48px;
  }
  
  .tagline{
    font-size: 20px;
  }
  .padding{
    width:50%;
  }
  
  img{
    width:98%;
    padding:2%;
    object-fit: cover;
    height:98%;
    transition: 0.2s ease-in-out;
    border-radius: 16px;
  }
  
  img:hover{
    border-radius: 0px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  .navinner{
    display: flex;
    justify-content: space-between;
    width:98%;

  }
  .top-right{
    padding:20px 16px;
  
  }
}
