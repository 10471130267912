@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://db.onlinewebfonts.com/c/c082f6221a530d6432e92c881ad7a686?family=HM+Amperserif+Disp+W02+Regular');

@font-face {
  font-family: 'Sk';
  src: local('Gilroy'), url(./fonts/Sk-Modernist-Bold.otf) format('truetype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Sk';
  src: local('MyFont'), url(./fonts/Sk-Modernist-Regular.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body{
  margin:0px;
  padding:0px;
  /* font-family: 'Marcellus'; */
  font-family: 'HM Amperserif Disp W02 Regular';
  background-color: white;
  color: #060606;
  /* background-color: #060606;
  color: white; */
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.ReactModal__Overlay {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(16px);
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Content {
  width: 30%; 
  height: 100%; 
  max-height: 80%; 
  margin: auto;
  border: 4px solid #ffffff;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  /* .ReactModal__Overlay {
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.5) !important;
  } */

  .ReactModal__Content {
    width: 60%; 
    height: 50vh; 
    max-height: 80%; 
    margin: auto;
    border: 4px solid #ffffff;
    border-radius: 20px;
    overflow: hidden;
  }
}
